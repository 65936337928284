import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import PhotoModal from 'views/modals/PhotoModal'
import Quill from 'quill'
import Delta from 'quill-delta'
import FrenchQuotes from './formats/FrenchQuotes'
import EnglishQuotes from './formats/EnglishQuotes'
import Preface from './formats/Preface'

const PhotoModalComponent = Vue.extend(PhotoModal)

const scroll = {
  get () {
    return window.pageYOffset || document.documentElement.scrollTop
  },
  set (yPos) {
    window.scrollTo(0, yPos)
  }
}

function returnScroll (quill, index, scrollTop) {
  return new Promise((resolve) => {
    setTimeout(() => {
      quill.setSelection(index, 0)
      scroll.set(scrollTop + window.innerHeight/2)
      resolve()
    })
  })
}

const openPhotoModal = (propsData = {
  visible: true,
  title: 'Выбор фотографии'
}) => {
  return new PhotoModalComponent({
    el: document.createElement('div'),
    vuetify: vuetify,
    propsData
  })
}

export default {
  'link': function (value) {
    if (value) {
      let href = window.prompt('Enter the URL')
      if (href && href.trim() !== '') {
        this.quill.format('link', href, Quill.sources.USER)
        return
      }
    }
    this.quill.format('link', false, Quill.sources.USER)
  },
  'image': function (value) {
    const scrollTop = scroll.get()
    let range = this.quill.getSelection()

    if (value) {
      let photoModal = openPhotoModal()
      photoModal.$once('choose', (image) => {
        let data = {
          url: image.url,
          caption: image.title
        }

        // this.quill.updateContents(
        //   new Delta([
        //     {
        //       insert: { image: 'https://octodex.github.com/images/labtocat.png' },
        //       attributes: { alt: "Lab Octocat" }
        //     }
        //   ]).retain(this.quill.getSelection().index)
        // )

        this.quill.insertEmbed(range.index, 'image', data, Quill.sources.USER)
        returnScroll(this.quill, range.index, scrollTop)
      })
    } else {
      this.quill.format('image', false)
    }
  },
  'french-quotes': FrenchQuotes.handler,
  'english-quotes': EnglishQuotes.handler,
  'preface': Preface.handler
}
