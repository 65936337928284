import handlers from './handlers'
import 'quill-paste-smart';

export const QUILL_BASIC = {
  modules: {
    toolbar: {
      container: [
        ['preface', 'bold', 'italic'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['french-quotes', 'english-quotes'],
        ['link'],
        ['clean']
      ],
      handlers: handlers
    }
  }
}

export const QUILL_DOCTOR_PROMO = (function () {
  const config = { ...QUILL_BASIC }
  config.modules.toolbar.container[0].push({'header': 3})
  return config
})()

export const QUILL_LIMIT_MAX_LENGTH = { ...QUILL_BASIC.modules, limit: true }

// :)
export const QUILL_SIMPLE = {
  modules: {
    toolbar: {
      container: [
        ['bold', 'italic'],
        ['french-quotes', 'english-quotes'],
        ['link'],
        ['clean']
      ],
      handlers: handlers
    }
  }
}

export const QUILL_EXTENDED = {
  modules: {
    booking: true,
    claim: true,
    toolbar: {
      container: [
        ['preface', 'bold', 'italic'],
        ['blockquote'],
        [{'header': 2}, {'header': 3}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['french-quotes', 'english-quotes'],
        ['link', 'image', 'booking', 'iframe', 'claim'],
        ['clean']
      ],
      handlers: handlers
    }
  }
}
